<template>
<div>
    <div id="fake-container">
        <b-img id="shop-banner" src="../assets/Shop_Banner.png"></b-img>
    </div>
    <!-- SHOP SECONDARY NAME HEADER: Shows up on multiple screens -->
    <div id="shop-name-box" v-if="shop && showshopsearch === 'no'">
        <b-row align-v="center" align-h="center" class="max-100">
            <div class="shop-name-secondary">
                {{ shop.employer_name }}
            </div>
            <div v-if="shop.formerly_known_as">(Formerly: {{ shop.formerly_known_as }})</div>
        </b-row>
    </div>
    <div class="content" v-if="user.employer_access === '1'" :style="(shop.formerly_known_as) ? 'margin-top: 8.5em;' : ''">

        <!-- SEARCH HISTORY -->
        <template v-if="showshopsearch === 'yes'">
            <b-link id="shop-search-history" @click="$bvModal.show('search-history-modal')">
                <font-awesome-icon icon="history"></font-awesome-icon>
            </b-link>
        </template>


        <!-- SEARCH HISTORY MODAL -->
        <b-modal no-fade id="search-history-modal">
            <template v-slot:modal-header="">
                <b-col>
                    <b-row align-h="center">
                        Recently viewed shops:
                    </b-row>
                </b-col>


            </template>

            <template v-slot:default="">
                <div v-if="shophistory.length > 0" class="mb-2 max-100" align-h="center">
                    <div class="buttons" v-for="key in shophistory" :key-value="key.employer_no" v-bind:key="key.employer_no">
                        <b-button block @click.prevent="shopReSelect">
                            <div class="non-clickable">{{ key.employer_name }}</div>
                        </b-button>
                    </div>
                </div>
                <div v-if="shophistory.length === 0">
                    No shops viewed.
                </div>

            </template>

            <template v-slot:modal-footer="">
                <b-container fluid>
                    <a class="clear-search-history-link" href="#" @click.prevent="shopHistoryClear">
                        <div class="clear-search-history-button">Clear Search History</div>
                    </a>
                </b-container>

            </template>
        </b-modal>

        <!-- SEARCH SHOP NAME -->
        <template>
            <form id="shopSearch" v-if="showshopsearch === 'yes'" @submit.prevent="submitShopName">
                <div class="input-group custominput">
                    <input id="shopSearchInput" class="form-control" name="shop-search" type="text" placeholder="Search Shop Name" v-model="form.searchedshop">
                    <div class="input-group-nest">
                        <button class="btn searchbutton" type="submit">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </button>
                    </div>
                </div>
            </form>
        </template>


        <div class="sub-content">

            <!-- ERROR MESSAGE -->
            <b-row id="errormsg" v-if="error" align-h="center" class="max-100"><b>{{ error }}</b></b-row>

            <!-- SHOP SEARCH RESULTS -->
            <template v-if="foundshops[0]">
                <div id="foundshops-header" align-h="center" class="mb-2 max-100"><b>Select a shop from the list below.</b></div>
                <div id="shops-results">
                    <div id="shops-results-container">
                        <div id="shops-results-list">
                            <div class="buttons" v-for="shop in foundshops" v-bind:key="shop.employer_no" v-bind:value="shop.employer_no">
                                <b-button block @click.prevent="shopSelect">
                                    <div class="button-top-row">{{ shop.employer_name }}</div>
                                    <div v-if="isShopActive(shop.status)" class="button-bottom-row">
                                        <div v-if="shop.formerly_known_as != null">Formerly: {{ shop.formerly_known_as }}</div>
                                        <div v-if="shop.location_address_1 != null">{{ shop.location_address_1 }}</div>
                                        <div v-if="shop.location_address_2 != null">{{ shop.location_address_2 }}</div>
                                        <div>{{ shop.location_city }} {{ shop.location_state }} {{ shop.location_zip }}</div>
                                    </div>
                                    <div v-if="!isShopActive(shop.status)" class="button-bottom-row-red">IN-ACTIVE</div>
                                </b-button>
                            </div>

                            <div id="shops-hidden-div"></div>

                        </div>
                    </div>
                </div>
                <div id="shops-list-arrow-more">
                    <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                </div>

            </template>

            <!-- SHOP DETAIL -->
            <template v-if="shop && (showshopsearch === 'no') && (showshop === 'yes')">

                <div id="shop-top-box">
                    <!-- LOCATION ADDRESS  -->
                    <div class="shop-address-header max-100">Location Address:</div>
                    <div class="shop-location-data">
                        <a :href="shop.shopaddresslink1">
                            <a :href="shop.shopaddresslink2">
                                <div v-if="shop.location_address_1 != null">{{ shop.location_address_1 }}</div>
                                <div v-if="shop.location_address_2 != null">{{ shop.location_address_2 }}</div>
                                <div>{{ shop.location_city }}, {{ shop.location_state }} {{ shop.location_zip}}</div>
                            </a>
                        </a>
                        <div v-if="shop.location_telephone">
                            <a href="shop.location_tele_href">{{ shop.location_telephone }}</a>
                        </div>
                        <div v-if="!shop.location_telephone" class="gray-phone">No phone on record.</div>
                    </div>


                    <!-- BILLING ADDRESS -->
                    <div class="shop-address-header max-100">Billing Address:</div>
                    <div class="shop-billing-data">
                        <div v-if="shop.billing_address_1 != null">{{ shop.billing_address_1 }}</div>
                        <div v-if="shop.billing_address_2 != null">{{ shop.billing_address_2 }}</div>
                        <div>{{ shop.billing_city }}, {{ shop.billing_state }} {{ shop.billing_zip }}</div>
                        <div v-if="shop.billing_telephone">
                            <a :href="shop.billing_tele_href">{{ shop.billing_telephone }}</a>
                        </div>

                    </div>
                </div>
            </template>



            <!-- SHOP CONTACTS  -->
            <template v-if="shop && showshopsearch === 'no' && showcontacts === 'yes'">
                <div id="shop-contacts-results-container">
                    <div id="shop-contacts-results-list">
                        <div class="shop-contact-box" v-for="shopcontact in shopcontacts" v-bind:key="shopcontact.contact_name">
                            <div class="contact-name-bar" align-v="center">
                                {{ shopcontact.contact_name }}
                            </div>
                            <div class="contact-data">
                                <div>Role: {{ shopcontact.contact_desc }}</div>
                                <div>Address:</div>
                                <div class="contact-address">
                                    <div v-if="shopcontact.contact_address_l1 != null">{{ shopcontact.contact_address_l1 }}</div>
                                    <div v-if="shopcontact.contact_address_l2 != null">{{ shopcontact.contact_address_l2 }}</div>
                                    <div>{{ shopcontact.contact_city }} {{ shopcontact.contact_state }} {{ shopcontact.contact_zip }}</div>
                                </div>
                                <div>Tel:
                                    <span v-if="shopcontact.contact_tel_no != '()-'
                                                && shopcontact.contact_tel_no != '() -'">
                                        <a :href="shopcontact.contact_tel_href">{{ shopcontact.contact_tel_no }}</a>
                                    </span>
                                </div>
                                <div>Fax:
                                    <span v-if="shopcontact.contact_fax_no != '()-'
                                                && shopcontact.contact_fax_no != '() -'">{{ shopcontact.contact_fax_no }}
                                    </span>
                                </div>

                                <div>Email:
                                    <a :href="shopcontact.contact_email_href">{{ shopcontact.contact_email }}</a>
                                </div>
                            </div>
                        </div>
                        <div id="shop-contacts-hidden-div"></div>

                    </div>
                </div>

                <div id="shop-contacts-list-arrow-more">
                    <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                </div>

            </template>


            <!-- SHOP DIVISIONS  -->
            <template v-if="shop && showshopsearch === 'no' && showdivisions === 'yes'">
                <div id="shop-divisions">
                    <div id="shop-divisions-container">
                        <div id="shop-divisions-results-list">

                            <!-- DIVISION ADDRESS  -->
                            <div v-for="(shopdivision, obj) in shopdivisions" v-bind:key="obj">
                                <div class="shop-division-box">
                                    <div class="shop-division-header max-100">{{ shopdivision.division_name }}</div>
                                    <div class="shop-division-data">
                                        <a :href="shopdivision.shopaddresslink1">
                                            <a :href="shopdivision.shopaddresslink2">
                                                <div v-if="shopdivision.location_address_1 != null">{{ shopdivision.location_address_1 }}</div>
                                                <div v-if="shopdivision.location_address_2 != null">{{ shopdivision.location_address_2 }}</div>
                                                <div>{{ shopdivision.location_city }} {{ shopdivision.location_state }} {{ shopdivision.location_zip}}</div>
                                            </a>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id="shop-divisions-hidden-div"></div>
                        </div>
                    </div>
                    <div id="shop-divisions-list-arrow-more">
                        <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                    </div>
                </div>

            </template>

            <!-- SHOP CONTRACTS  -->
            <template v-if="shop && showshopsearch === 'no' && showcontracts === 'yes'">
                <div>
                    <!-- CONTRACTS LIST  -->
                    <div id="documents" v-if="hascontracts === 'yes'">
                        <div id="docs-header">
                            <div class="docs-label">CONTRACTS</div>
                        </div>
                        <div id="docs-list-container">
                            <div id="docs-list">
                                <div class="document" v-for="shoppagedoc in shopcontracts" :key="shoppagedoc.document_name">
                                    <b-link href="#" @click.prevent="shopPageDocGet" :docname="shoppagedoc.document_name">
                                        {{ shoppagedoc.document_desc }}
                                    </b-link>
                                </div>
                                <div id="docs-hidden-div"></div>
                            </div>

                        </div>
                        <div id="docs-list-arrow-more" v-if="hascontracts === 'yes'">
                            <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                        </div>

                    </div>

                    <div id="shop-contracts" v-if="shopcontract && showshopsearch === 'no' && showcontracts === 'yes'">
                        <div class="contract-data">
                            <div><span class="contract-label">Contract No: </span>{{ shopcontract.contract_no }}</div>
                            <div><span class="contract-label">Signed Date: </span>{{ shopcontract.contract_date }}</div>
                            <div><span class="contract-label">Effective Date: </span>{{ shopcontract.effective_date }}</div>
                            <div><span class="contract-label">Expiration Date: </span>{{ shopcontract.expiration_date }}</div>
                            <div><span class="contract-label">FMCS Completed By: </span>{{ shopcontract.fmcs_completed_by }}</div>
                            <div><span class="contract-label">FMCS Completed Date: </span>{{ shopcontract.fmcs_completed_date }}</div>
                            <div><span class="contract-label">Dues Billing Frequency: </span>{{ shopcontract.dues_billing_frequency }}</div>
                            <div><span class="contract-label">Dues Amount: </span>{{ shopcontract.dues_amt }}</div>
                            <div><span class="contract-label">Initiation: </span>{{ shopcontract.init_fee }}</div>
                        </div>
                    </div>
                </div>
            </template>


            <!-- SHOP MEMBERS DETAIL -->
            <template v-if="shop && showshopsearch === 'no' && showshopmembers === 'yes'">

                <div id="shop-members-box">
                    <!-- FIELD HEADERS -->
                    <div id="shop-members-list">
                        <table>
                            <tr id="shop-members-fields">
                                <th>SSN</th>
                                <th>LastName</th>
                                <th>FirstName</th>
                                <th>UnionID</th>
                                <th>Address</th>
                                <th>Address (cont)</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Email</th>
                            </tr>
                            <tr class="shop-members" v-for="shopmember in shopmembers" :key="shopmember.alt_id">
                                <td>{{ shopmember.member_ss_no }}</td>
                                <td>{{ shopmember.member_name_l }}</td>
                                <td>{{ shopmember.member_name_f }}</td>
                                <td>{{ shopmember.alt_id }}</td>
                                <td>{{ shopmember.address_1 }}</td>
                                <td>{{ shopmember.address_2 }}</td>
                                <td>{{ shopmember.city }}</td>
                                <td>{{ shopmember.state }}</td>
                                <td>{{ shopmember.zip }}</td>
                                <td>{{ shopmember.member_email }}</td>

                            </tr>
                        </table>
                    </div>

                </div>
            </template>


            <!-- NOTES -->
            <template v-if="shop && showshopsearch === 'no' && showshopnotes === 'yes'">
                <div>
                    <div id="notes">
                        <div id="notes-list">
                            <div v-for="(shopnote, obj) in shopnotes" v-bind:key="obj">
                                <div class="shopnote-header">
                                    <span class="shopnote-username">{{ shopnote.user_id }}: </span>
                                    <span>{{ shopnote.entered_date }}</span>
                                </div>
                                <div class="shopnote-nullackby" v-if="shopnote.ack_by == null">Not Acknowledged</div>
                                <div class="shopnote-ackby" v-if="shopnote.ack_by != null">Acknowledged by {{ shopnote.ack_by }}: {{ shopnote.ack_date }}</div>
                                <b-row class="shopnote-note">{{ shopnote.note }}
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <b-row class="notes-header" v-if="showshopnotes === 'yes'">
                        <b-link id="add-note" @click="$bvModal.show('notes-modal')">
                            <!-- <div class="add-note"> -->
                            ADD NOTE
                            <!-- </div> -->
                        </b-link>
                    </b-row>

                    <b-modal no-fade id="notes-modal">
                        <template v-slot:modal-header="">
                        </template>

                        <template v-slot:default="">
                            <div>
                                <b-form-textarea id="notes-middle" v-model="shopnotetext" rows="3" max-rows="6"></b-form-textarea>

                            </div>
                        </template>

                        <template v-slot:modal-footer="">
                            <b-container fluid>
                                <a class="add-note-link" href="#" @click.prevent="noteAdd">
                                    <div class="add-note-button">Add Note</div>
                                </a>
                            </b-container>

                        </template>

                    </b-modal>
                </div>
            </template>

        </div>

        <!-- BOTTOM SHOP MENU BAR  -->
        <template>
            <b-row id="shop-bottom-menu" align-v="center" class="max-100" v-if="shop">

                <!-- SHOP ICON -->
                <b-col class="shop-menu-col">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showshop === 'yes'"></div>
                    </div>
                    <b-link id="get-shop" @click.prevent="shopGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageEmployer.png"></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">SHOP</b-row>
                    </b-link>
                </b-col>

                <!-- CONTACTS ICON -->
                <b-col class="shop-menu-col" align-h="center" v-if="hascontacts === 'yes'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showcontacts === 'yes'"></div>
                    </div>
                    <b-link id="get-contacts" @click.prevent="contactsGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageContacts.png"></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">CONTACTS</b-row>
                    </b-link>
                </b-col>
                <b-col class="shop-menu-col" v-if="hascontacts === 'no'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showcontacts === 'never'"></div>
                    </div>
                    <b-row align-h="center">
                        <div id="no-contacts">
                            <b-img class="shop-menu-img" fluid src="../assets/MemberPage_No_Dependents.png"></b-img>
                        </div>
                    </b-row>
                    <b-row class="shop-bottom-menu-label no-contacts" align-h="center">CONTACTS</b-row>
                </b-col>

                <!-- DIVISIONS ICON -->
                <b-col class="shop-menu-col" v-if="hasdivisions === 'yes'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showdivisions === 'yes'"></div>
                    </div>
                    <b-link id="get-divisions" @click.prevent="divisionsGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageDivisions.png"></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">DIVISIONS</b-row>
                    </b-link>
                </b-col>
                <b-col class="shop-menu-col" v-if="hasdivisions === 'no'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showdivisions === 'never'"></div>
                    </div>
                    <b-row align-h="center">
                        <div id="no-divisions">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageNoDivisions.png"></b-img>
                        </div>
                    </b-row>
                    <b-row class="shop-bottom-menu-label no-divisions" align-h="center">DIVISIONS</b-row>
                </b-col>


                <!-- CONTRACTS ICON -->
                <b-col class="shop-menu-col" v-if="hascontract === 'yes'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showcontracts === 'yes'"></div>
                    </div>
                    <b-link id="get-contracts" @click.prevent="contractsGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageContracts.png"></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">CONTRACTS</b-row>
                    </b-link>
                </b-col>
                <b-col class="shop-menu-col" v-if="hascontract === 'no'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showcontracts === 'never'"></div>
                    </div>
                    <b-row align-h="center">
                        <div id="no-contracts">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageNoContracts.png"></b-img>
                        </div>
                    </b-row>
                    <b-row class="shop-bottom-menu-label no-contracts" align-h="center">CONTRACTS</b-row>
                </b-col>


                <!-- MEMBER ICON -->
                <b-col class="shop-menu-col" v-if="hasshopmembers === 'yes'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showshopmembers === 'yes'"></div>
                    </div>
                    <b-link id="get-shop-member" @click.prevent="shopMembersGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" fluid src="../assets/MemberPage_Member.png"></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">MEMBERS</b-row>
                    </b-link>
                </b-col>
                <b-col class="shop-menu-col" v-if="hasshopmembers === 'no'">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showshopmembers === 'never'"></div>
                    </div>
                    <b-row align-h="center">
                        <div id="no-shopmembers">
                            <b-img class="shop-menu-img" fluid src="../assets/ShopPageNoMembers.png"></b-img>
                        </div>
                    </b-row>
                    <b-row class="shop-bottom-menu-label no-shopmembers" align-h="center">MEMBERS</b-row>
                </b-col>

                <!-- NOTES ICON -->
                <b-col class="shop-menu-col">
                    <div class="shop-menu-selector-container">
                        <div class="shop-menu-selector" v-show="showshopnotes === 'yes'"></div>
                    </div>
                    <b-link id="shop-notes" @click.prevent="shopNotesGet">
                        <b-row align-h="center">
                            <b-img class="shop-menu-img" src="../assets/ShopPageNotes.png" fluid></b-img>
                        </b-row>
                        <b-row class="shop-bottom-menu-label" align-h="center">NOTES</b-row>
                    </b-link>
                </b-col>

            </b-row>

        </template>

    </div>
    <div class="footer">
    </div>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'

library.add(faHistory, faSearch, faBuilding, faAddressBook, faLongArrowAltDown)

export default {
    name: 'shops',

    computed: {
        ...mapGetters({
            count: 'documents/count',
            user: 'auth/user',
            error: 'shops/error',
            showshopsearch: 'shops/showshopsearch',
            foundshops: 'shops/foundshops',
            shophistory: 'shops/shophistory',
            shop: 'shops/shop',
            showshop: 'shops/showshop',
            shopcontacts: 'shops/shopcontacts',
            hascontacts: 'shops/hascontacts',
            showcontacts: 'shops/showcontacts',
            shopdivisions: 'shops/shopdivisions',
            hasdivisions: 'shops/hasdivisions',
            showdivisions: 'shops/showdivisions',
            shopcontract: 'shops/shopcontract',
            hascontract: 'shops/hascontract',
            showcontract: 'shops/showcontract',
            shopcontracts: 'shops/shopcontracts',
            hascontracts: 'shops/hascontracts',
            showcontracts: 'shops/showcontracts',
            hasshopmembers: 'shops/hasshopmembers',
            showshopmembers: 'shops/showshopmembers',
            shopmembers: 'shops/shopmembers',
            shopnotes: 'shops/shopnotes',
            showshopnotes: 'shops/showshopnotes',
        }),
    },

    data: function() {
        return {
            pagetitle: 'ShopSearch',

            form: {
                searchedshop: '',
            },

            shopnotetext: '',
            employernew: true,
        }

    },

    methods: {
        ...mapActions({
            searchShopName: 'shops/searchShopName',
            selectShop: 'shops/selectShop',
            setHideShopSearch: 'shops/setHideShopSearch',
            clearShopHistory: 'shops/clearShopHistory',
            setShopHistory: 'shops/setShopHistory',
            setMenuTab: 'shops/setMenuTab',
            setDoc: 'documents/setDoc',
            setPreviousPage: 'documents/setPreviousPage',
            getShopNotes: 'shops/getShopNotes',
            addShopNote: 'shops/addShopNote',
            setShopNoteText: 'shops/setShopNoteText',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
        }),

        flashList(element_array) {
            if (this.endOfListVisible(element_array)) {
                var dlc = document.getElementById(element_array.containerName);
                var lam = document.getElementById(element_array.listArrowName);

                setTimeout(function() {
                    if (dlc && lam) {
                        dlc.classList.add('flash');
                        lam.classList.add('flash');
                    }

                    setTimeout(function() {
                        if (dlc && lam) {
                            dlc.classList.remove('flash');
                            lam.classList.remove('flash');
                        }

                        setTimeout(function() {
                            if (dlc && lam) {
                                dlc.classList.add('flash');
                                lam.classList.add('flash');
                            }

                        }, 100)
                    }, 250)
                }, 400)
            }
        },

        onScroll(element_array) {
            // console.log(element_array)
            try {
                document.getElementById(element_array.containerName)
                    .addEventListener("scroll", () => this.endOfListVisible(element_array));
            } catch {
                //
            }
        },

        endOfListVisible(element_array) {
            try {
                if (document.getElementById(element_array.hiddenDivName).getBoundingClientRect().bottom - 60 >
                    document.getElementById(element_array.listArrowName).getBoundingClientRect().bottom) {
                    document.getElementById(element_array.containerName).classList.add('flash');
                    document.getElementById(element_array.listArrowName).style.visibility = 'visible';
                    return true;
                } else {
                    document.getElementById(element_array.listArrowName).style.visibility = 'hidden';
                    return false;
                }

            } catch {
                //
            }
        },

        submitShopName() {
            if (this.form.searchedshop == '') {
                // do nothing
            } else {
                this.turnOnLoadingMsg().then(() => {
                    var params = {
                        user_id: this.user.user_id,
                        searchedshop: this.form.searchedshop
                    };
                    this.searchShopName(params).then(() => {
                        this.turnOffLoadingMsg().then(() => {
                            var shops_element_array = {
                                containerName: 'shops-results-container',
                                listArrowName: 'shops-list-arrow-more',
                                hiddenDivName: 'shops-hidden-div'
                            }
                            this.flashList(shops_element_array);
                            this.onScroll(shops_element_array);
                        })
                    }) //.catch(e => { console.log(e); })


                })

            }

        },

        isShopActive(shop) {
            if (shop === 'ACTIVE') {
                return true;
            } else {
                return false;
            }
        },

        shopSelect: function(event) {
            var shopid = event.target.parentNode.getAttribute('value');

            this.turnOnLoadingMsg().then(() => {
                var params = {
                    user_id: this.user.user_id,
                    shopid: shopid
                };

                this.selectShop(params).then(() => {
                    this.form['searchedshop'] = '';
                    this.setHideShopSearch();
                    this.addShopHistory();
                    this.setMenuTab('SET_SHOWSHOP');
                    window.scrollTo({ top: 0 });
                    this.turnOffLoadingMsg();

                }) //.catch(e => { console.log(e); })

            }) //.catch(e => { console.log(e); })

        },

        shopReSelect: function(event) {
            var shopid = event.target.parentNode.getAttribute('key-value');
            this.$bvModal.hide('search-history-modal');

            this.turnOnLoadingMsg().then(() => {
                var params = {
                    user_id: this.user.user_id,
                    shopid: shopid
                };

                this.selectShop(params).then(() => {
                    this.form['searchedshop'] = '';
                    this.setHideShopSearch();
                    this.addShopHistory();
                    this.setMenuTab('SET_SHOWSHOP');
                    window.scrollTo({ top: 0 });
                    this.turnOffLoadingMsg();

                }) //.catch(e => { console.log(e); })

            }) //.catch(e => { console.log(e); })
        },

        addShopHistory() {
            var a = {};
            a = {
                employer_no: this.shop.employer_no,
                employer_name: this.shop.employer_name,
            };

            if (this.shophistory.length > 0) {

                // set flag
                this.employernew = true;
                for (var key in this.shophistory) {
                    // check if current employer is already in search history
                    if (this.employernew && this.shophistory[key].employer_no === a.employer_no) {
                        this.employernew = false;
                    }

                }

                // employer is new
                if (this.employernew) {
                    this.shophistory.reverse();
                    this.shophistory.push(a);
                    this.shophistory.reverse();

                } else {
                    // employer exists, remove from list
                    var employerremove = this.shop.employer_no;

                    var newarray = this.shophistory.filter(function(item) {
                        return item.employer_no != employerremove
                    })

                    this.setShopHistory(newarray);

                    // add to top of list
                    this.shophistory.reverse();
                    this.shophistory.push(a);
                    this.shophistory.reverse();

                }

                //reduce list size
                this.shophistory.length = Math.min(this.shophistory.length, 10);

            } else {
                this.setShopHistory([a]);

            }
        },

        shopHistoryClear() {
            this.clearShopHistory();
        },


        shopGet() {
            this.turnOnLoadingMsg().then(() => {

                this.setHideShopSearch();
                this.setMenuTab('SET_SHOWSHOP');
                window.scrollTo({ top: 0 });
                this.turnOffLoadingMsg();

            }) //.catch(e => { console.log(e); })
        },


        contactsGet() {
            this.turnOnLoadingMsg().then(() => {

                this.setHideShopSearch();
                this.setMenuTab('SET_SHOWCONTACTS');
                window.scrollTo({ top: 0 });
                this.turnOffLoadingMsg().then(() => {
                    var contacts_element_array = {
                        containerName: 'shop-contacts-results-container',
                        listArrowName: 'shop-contacts-list-arrow-more',
                        hiddenDivName: 'shop-contacts-hidden-div'
                    }
                    this.flashList(contacts_element_array);
                    this.onScroll(contacts_element_array);
                })

            }) //.catch(e => { console.log(e); })
        },

        divisionsGet() {
            this.turnOnLoadingMsg().then(() => {

                this.setHideShopSearch();
                this.setMenuTab('SET_SHOWDIVISIONS');
                window.scrollTo({ top: 0 });
                this.turnOffLoadingMsg().then(() => {
                    var divisions_element_array = {
                        containerName: 'shop-divisions-container',
                        listArrowName: 'shop-divisions-list-arrow-more',
                        hiddenDivName: 'shop-divisions-hidden-div'
                    }
                    this.flashList(divisions_element_array);
                    this.onScroll(divisions_element_array);
                })

            }) //.catch(e => { console.log(e); })
        },

        contractsGet() {
            this.turnOnLoadingMsg().then(() => {

                this.setHideShopSearch();
                this.setMenuTab('SET_SHOWCONTRACTS');
                window.scrollTo({ top: 0 });
                this.turnOffLoadingMsg().then(() => {
                    var docs_element_array = {
                        containerName: 'docs-list-container',
                        listArrowName: 'docs-list-arrow-more',
                        hiddenDivName: 'docs-hidden-div'
                    }
                    this.flashList(docs_element_array);
                    this.onScroll(docs_element_array);
                })


            }) //.catch(e => { console.log(e); })
        },

        shopMembersGet() {
            this.turnOnLoadingMsg().then(() => {

                this.setHideShopSearch();
                this.setMenuTab('SET_SHOWSHOPMEMBERS');
                window.scrollTo({ top: 0 });
                this.turnOffLoadingMsg().then(() => {})


            }) //.catch(e => { console.log(e); })
        },

        shopPageDocGet: function(event) {
            this.setPreviousPage('shops');

            var docname = event.target.getAttribute('docname');
            this.turnOnLoadingMsg().then(() => {
                this.setDoc(docname);
                this.$router.replace({
                    name: 'docs'
                }) //.catch(e => { console.log(e); })

            }) //.catch(e => { console.log(e); })

        },

        shopNotesGet() {
            this.turnOnLoadingMsg().then(() => {
                var params = {
                    user_id: this.user.user_id,
                    shopid: this.shop.employer_no
                };

                this.getShopNotes(params).then(() => {
                    this.setHideShopSearch();
                    this.setMenuTab('SET_SHOWSHOPNOTES');
                    window.scrollTo({ top: 0 });
                    this.turnOffLoadingMsg();

                }) //.catch(e => { console.log(e); })

            }) //.catch(e => { console.log(e); })

        },

        noteAdd() {
            if (this.notetext != '') {
                this.turnOnLoadingMsg().then(() => {
                    this.setShopNoteText(this.shopnotetext).then(() => {
                        this.addShopNote(this.user.user_id).then(() => {

                        }).then(() => {
                            this.shopnotetext = '';
                            this.$bvModal.hide('notes-modal');
                            this.shopNotesGet();
                            window.scrollTo({ top: 0 });
                            this.turnOffLoadingMsg();

                        }) //.catch(e => { console.log(e); })

                    }) //.catch(e => { console.log(e); })

                }) //.catch(e => { console.log(e); })

            }

        },



    }



}
</script>

<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    max-height: 40vh;
}

#shop-banner {
    vertical-align: middle;
    border-style: none;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    float: right;
    z-index: 0;
}

#shop-search-history {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding-right: 0.75em;
    transform: translateY(0.4em);
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    width: 50vw;
    float: right;
    text-align: right;
    z-index: 1020;
}

.clear-search-history-link {
    margin: auto;
    text-align: center;
    text-decoration: none;
    outline: none;
}

.clear-search-history-button {
    font-weight: bold;
    color: white;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color: #0f70b7;
}

.content {
    position: absolute;
    background-color: transparent;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    margin-top: 7em;
    width: 100%;
    height: calc(100vh - 8.5em);
    overflow-y: scroll;
}

.sub-content {
    background-color: transparent;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding-top: 1em;
    width: 100%;
    height: 100%;

}

#errormsg {
    position: relative;
    z-index: 100;
    margin-bottom: 2vh;
    color: red;
    font-weight: bold;
}

.max-100 {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
}



#shopSearch {
    position: fixed;
    min-width: 100vw;
    max-width: 100vw;
    top: 3.5em;
    padding: 1vw;
    min-height: 3.5em;
    z-index: 100;
}


#foundshops-header {
    position: fixed;
    top: 7em;
    margin-top: 1em;
    z-index: 100;
    text-align: center;
    color: white;
}

#shops-results-container {
    position: fixed;
    top: 10em;
    overflow-y: scroll;
    width: 90vw;
    margin-left: 5vw;
    white-space: nowrap;
    min-height: calc(100vh - 13em);
    max-height: calc(100vh - 13em);
    z-index: 150;
}

#shop-contacts-results-container,
#shop-divisions-container {
    position: relative;
    overflow-y: scroll;
    max-width: 90vw;
    margin-left: 5vw;
    padding-left: 5vw;
    white-space: nowrap;
    min-height: calc(100vh - 14em);
    max-height: calc(100vh - 14em);
    z-index: 150;
}

#docs-list-container {
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;
    height: calc(35vh - 2em);
    line-height: 2.5;
    max-width: 90vw;
    z-index: 150;
}

#docs-list {
    max-width: 80vw;
    margin-left: 5vw;
}

#shops-results-container.flash,
#shop-contacts-results-container.flash,
#shop-divisions-container.flash,
#docs-list-container.flash {
    border-bottom: 1px solid #0d2365;
}

#shop-contacts-results-list,
#shop-divisions-results-list {
    max-width: 80vw;
}

#shops-list-arrow-more {
    position: fixed;
    top: calc(100vh - 3em);
    max-width: 5vw;
    transform: translateX(87vw);
    font-size: 2em;
    color: transparent;
}

#docs-list-arrow-more {
    max-width: 5vw;
    transform: translate(87vw, -1.5em);
    font-size: 2em;
    color: transparent;
}

#shop-contacts-list-arrow-more,
#shop-divisions-list-arrow-more {
    max-width: 5vw;
    transform: translate(92vw, -1.5em);
    font-size: 2em;
    color: transparent;
}


#shops-list-arrow-more.flash,
#shop-contacts-list-arrow-more.flash,
#shop-divisions-list-arrow-more.flash,
#docs-list-arrow-more.flash {
    color: #0d2365;
}

#shops-hidden-div,
#shop-contacts-hidden-div,
#shop-divisions-hidden-div,
#docs-hidden-div {
    height: 1px;
    visibility: hidden;
}


.custominput input[type=text] {
    margin: 0 0 0 0;
    width: 100%;
    min-height: 3em;
    outline: none;
    padding-right: 10vw;
}

.input-group {
    width: 90vw;
    transform: translateX(5vw);
}


.input-group-nest {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    outline: none !important;
}

button.searchbutton {
    font-size: 2em;
    width: 15vw;
    min-height: 100%;
    max-height: 100%;
    margin: 0 0 0 0;
    padding-right: 1.25em;
    width: 10vw;
    text-align: right;
    outline: none;
    border: none;
}

.btn {
    color: #0d2365;
    outline: none !important;
    box-shadow: none;
}

.btn:focus,
.btn:active,
.btn:hover {
    outline: none !important;
    box-shadow: none;
}

.buttons {
    margin-left: 10vw;
    margin-right: 10vw;
    word-wrap: normal;
    margin-bottom: 10px;
    padding: 0 0 0 0;
}

.non-clickable {
    pointer-events: none;

}


.button-top-row {
    white-space: normal;
    pointer-events: none;
    margin: 0 0 0 0;
    padding: 0.25em;
}

.button-bottom-row {
    pointer-events: none;
    margin: 0 0 0 0;
    padding: 0.25em;
    font-size: .75em;
    color: black;

}

.btn-secondary {
    background-color: white;
}

.btn-secondary:hover {
    background-color: #5a6268;
    color: white;
}


.button-bottom-row-red {
    margin: 0 0 0 0;
    padding: 0.25em;
    font-size: .75em;
    color: red;
    pointer-events: none;
}

#shop-top-box {
    position: relative;
    width: 80vw;
    margin-left: 10vw;
    border: 3px solid #e0e0e0;
    margin-bottom: 1em;
    background-color: white;
    z-index: 150;
}

.shop-address-header {
    font-family: arial;
    font-size: 1.1em;
    font-weight: bold;
    display: inline-flex;
    margin: 0 0 0 0;
    padding-left: 1em;
    padding-top: 1em;
    color: black;
    min-height: 1.5em;

}

.shop-location-data {
    font-family: monospace;
    font-size: 1.1em;
    padding-top: 0.25em;
    padding-left: 2em;
    padding-right: 2em;
    /* padding-bottom: 1.5em; */

}

.shop-billing-data {
    font-family: monospace;
    font-size: 1.1em;
    padding-top: 0.25em;
    padding-left: 2em;
    padding-right: 1.5em;
    padding-bottom: 1.5em;
    color: black;

}

.gray-phone {
    color: Gray;
    font-weight: bold;
}

.shop-name-secondary {
    text-align: center;
    width: 95%;
    font-size: 1.25em;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

#shop-name-box {
    position: fixed;
    color: white;
    top: 3.5em;
    min-height: 3.5em;
    width: 100vw;
    z-index: 100;
}

.shop-contact-box {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vw;
    padding-top: 2vw;
    border: 3px solid #e0e0e0;
    background-color: white;
    margin-bottom: 1em;
    border-radius: 0.25em;
}

.shop-division-box {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vw;
    padding-top: 2vw;
    border: 3px solid #e0e0e0;
    background-color: white;
    margin-bottom: 1em;
    border-radius: 0.25em;
}

.contact-name-bar,
.shop-division-header {
    font-family: arial;
    font-weight: bold;
    font-size: 1em;
    display: inline-flex;
    margin: 0 0 0 0;
    color: black;
    min-height: 1.5em;
    max-width: 100%;
    overflow-x: scroll;
}

.contact-data,
.shop-division-data {
    font-family: monospace;
    font-size: 1em;
    color: black;
    word-wrap: break-word;
    max-width: 70vw;
    overflow-x: auto;
}

.contact-address {
    padding-left: 5vw;
}

#documents {
    position: relative;
    height: 35vh;
    width: 90vw;
    margin-left: 5vw;
}

#docs-header {
    margin: 0 0 0 0;
    margin-left: 5vw;
    margin-bottom: 0.5em;
    min-height: 2em;
    max-height: 2em;
    font-weight: bold;
}

.docs-label {
    color: white;
}

#docs-icon {
    min-height: 1.5em;
    max-height: 1.5em;
}


.document {
    display: block;
    overflow-x: scroll;
    color: black;
    border-radius: 0.25em;
    border: 2px solid #0d2365;
    background-color: white;
    padding-top: .25em;
    padding-bottom: .25em;
    padding-left: .5em;
    padding-right: .5em;
    margin-bottom: 1em;
    text-decoration: none;
    max-width: 90vw;
    word-wrap: normal;
    white-space: nowrap;
}

#docs-list a {
    color: black;
    background-color: white;
    text-decoration: none;
    max-width: 80vw;
    word-wrap: normal;
    white-space: nowrap;
}

#shop-contracts {
    position: relative;
    width: 80vw;
    margin-top: 1em;
    margin-left: 10vw;
    margin-bottom: 6em;
    border: 3px solid #e0e0e0;
    background-color: white;
    z-index: 100;
    max-height: 35vh;
    overflow-y: auto;
}

.contract-address {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.contract-label {
    font-weight: bold;
}

.contract-data {
    font-family: monospace;
    font-size: 1.1em;
    padding: 5vw;
    color: black;

}


#shop-members-box {
    position: relative;
    width: 90vw;
    margin-left: 5vw;
    min-height: calc(100vh - 17em);
    max-height: calc(100vh - 17em);
    border: 3px solid #e0e0e0;
    margin-bottom: 1em;
    background-color: white;
    z-index: 150;
    font-size: 0.8em;
}

#shop-members-list {
    min-height: calc(100vh - 17em);
    max-height: calc(100vh - 17em);
    overflow-y: scroll;
    overflow-x: auto;
}

#shop-members-list table {
    width: 100%;
}

#shop-members-fields {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    white-space: nowrap;
    margin: 2px;
    background-color: black;
    color: white;
}

#shop-members-fields th {
    padding-left: 0.5em;

}

#shop-members-list tr {
    margin: 2px;
}

#shop-members-list tr:nth-child(even) {
    background-color: #b5bccf;
}

#shop-members-list td {
    white-space: nowrap;
    padding-left: 0.5em;
    padding-right: 0.75em;
    padding-top: 2px;
    padding-bottom: 2px;
}

.shop-members {
    width: 100%;
}

#notes {
    position: absolute;
    margin-bottom: 2em;
    min-height: calc(100vh - 17em);
    max-height: calc(100vh - 17em);
    background-color: white;
    width: 80vw;
    margin-left: 10vw;
    border: 3px solid #e0e0e0;
    z-index: 100;
}

#notes-list {
    padding-left: 1em;
    padding-right: 1em;
    min-height: calc(100vh - 17em);
    max-height: calc(100vh - 17em);
    overflow-y: scroll;
}

.notes-header {
    position: absolute;
    top: calc(100vh - 16em);
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-height: 2.5em;
    max-height: 2.5em;
    width: 100%;
    z-index: 100;
}

.shopnote-header {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.shopnote-username {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    font-weight: bold;

}

.shopnote-note {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

#add-note {
    margin-bottom: 6.5em;
    background-color: #0f70b7;
    color: white;
    font-weight: bold;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    border: 2px solid white;
    text-decoration: none;
    outline: none;
}

.add-note-link {
    margin: auto;
    text-align: center;
    text-decoration: none;
    outline: none;
}

.add-note-button {
    font-weight: bold;
    color: white;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color: #0f70b7;
}

.shopnote-nullackby {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    color: Gray;
    padding-left: 1em;
    padding-right: 1em;

}

.shopnote-ackby {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    color: Red;
    font-style: italic;
    padding-left: 1em;
    padding-right: 1em;

}

#shop-bottom-menu {
    position: fixed;
    bottom: 1.5em;
    min-height: 3.5em;
    max-height: 3.5em;
    z-index: 100;
    background-color: #e0e0e0;
    color: black;
    border-top: 2px solid white;

}

#get-shop,
#get-contacts,
#no-contacts,
#get-divisions,
#no-divisions,
#get-contracts,
#no-contracts,
#get-shop-member,
#no-shopmembers,
#shop-notes {
    display: block;
    padding-top: 0.25em;
    min-height: 2.25em;
    max-height: 2.25em;
    outline: none;
    text-decoration: none;
}

.shop-menu-col {
    min-height: 3.5em;
    max-height: 3.5em;
    min-width: 3.5em;
    outline: none;
}

.shop-menu-selector-container {
    min-height: 0.25em;
    max-height: 0.25em;

}

.shop-menu-selector {
    min-height: 0.25em;
    max-height: 0.25em;
    background-color: #0d2365;

}

.shop-menu-img {
    min-height: 2em;
    max-height: 2em;
    min-width: 2em;
    outline: none;
}

.shop-bottom-menu-label {
    font-size: .7em;
    color: black;
    text-decoration: none;
    outline: none;
    transform: translateY(-0.25em);
    margin-bottom: 0.5em;
}

/* make sure after .member-bottom-menu-label */
.no-contacts,
.no-contracts,
.no-divisions,
.no-shopmembers {
    color: #8f8f8f;
}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;
    background-color: #0d2365;

}
</style>
